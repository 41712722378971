import * as React from "react";
import GlobalWrapper from "components/core/GlobalWrapper";
import SEO from "components/core/SEO";
import { useTranslation } from "react-i18next";
import { graphql } from "gatsby";
import {
  CannedResponseTemplate,
  ChatTemplate,
  EmailTemplate,
  SurveyTemplate,
} from "app-types/template";
import CannedResponseTemplates from "components/ui/extended/CannedResponseTemplates";
import ChatTemplates from "components/ui/extended/ChatTemplates";
import PlainHeader from "components/ui/base/PlainHeader";
import EmailTemplates from "components/ui/extended/EmailTemplates";
import SurveyTemplates from "components/ui/extended/SurveysTemplates";
import { TRIGGER_TEMPLATES } from "constants/templates";

interface TemplatesPageProps {
  data: {
    allEmailsTemplates: {
      nodes: EmailTemplate[];
    };
    allCannedResponsesTemplates: {
      nodes: CannedResponseTemplate[];
    };
    allSurveysTemplates: {
      nodes: SurveyTemplate[];
    };
  };
  location: Location;
}

export default ({ data, location }: TemplatesPageProps) => {
  const { t } = useTranslation();

  const emailTemplates = data.allEmailsTemplates.nodes;
  const surveyTemplates = data.allSurveysTemplates.nodes;
  const cannedResponseTemplates = data.allCannedResponsesTemplates.nodes;

  const totalTemplates =
    emailTemplates.length +
    cannedResponseTemplates.length +
    TRIGGER_TEMPLATES.length +
    surveyTemplates.length;

  return (
    <GlobalWrapper withLayout location={location}>
      <SEO
        title={t("pages.templates.seo.title")}
        description={t("pages.templates.seo.description")}
        image={{
          relativePath: "meta/community-templates-for-saas.jpg",
          alt: "Success Stories from Customerly",
        }}
      />
      <PlainHeader
        title={t("pages.templates.title")}
        description={t("pages.templates.description", {
          count: totalTemplates,
        })}
        maxWidth={900}
        breadcrumbs={[
          {
            name: "Customerly",
            path: "/",
          },
          {
            name: "Templates",
            path: "/templates/",
          },
        ]}
      />

      <EmailTemplates showMore templates={emailTemplates.slice(0, 5)} />
      <ChatTemplates showMore templates={TRIGGER_TEMPLATES.slice(0, 5)} />
      <CannedResponseTemplates
        showMore
        templates={cannedResponseTemplates.slice(0, 5)}
      />
      <SurveyTemplates showMore templates={surveyTemplates.slice(0, 5)} />
    </GlobalWrapper>
  );
};
export const templatesPageQuery = graphql`
  query {
    allCannedResponsesTemplates {
      nodes {
        canned_response_template_id
        emoji
        description
        background
        canned_response {
          is_shared
          shortcut
          text
          title
        }
        author {
          account_id
          bio
          id
          public_name
          username
          website
        }
      }
    }
    allEmailsTemplates {
      nodes {
        name
        subject
        thumbnail_url
        description
        created_at
        community_email_template_id
        author {
          account_id
          bio
          id
          public_name
          username
          website
        }
      }
    }
    allSurveysTemplates {
      nodes {
        background
        description
        emoji
        survey_template_id
        name
        questions {
          title
          subtitle
          choices {
            value
          }
        }
        survey {
          name
          thankyou_text
        }
        author {
          account_id
          bio
          id
          public_name
          username
          website
        }
      }
    }
  }
`;
